<template>
  <div class="checkbox-form">
    <div class="answers">
      <label v-for="option in optionValues" :key="option" class="item">
        <span>{{ option }}</span>
        <input
          type="checkbox"
          :id="option"
          :value="option"
          v-model="checked"
          :checked="checked.includes(option)"
          @input="
            $emit(
              'update:modelValue',
              $event.target?.checked
                ? [...checked, $event.target?.value]
                : checked.filter(v => v !== $event.target?.value),
            )
          "
        />
        <span class="checkmark" />
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';

export default defineComponent({
  name: 'Checkbox',
  components: {},
  props: {
    options: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    checkedValues: {
      type: Array as PropType<Array<string>>,
      required: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const checked = ref(props.checkedValues ?? []);
    const optionValues = ref(props.options);

    return {
      checked,
      optionValues,
    };
  },
});
</script>

<style lang="scss">
.checkbox-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkbox-form .answers {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  font-family: Jost, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0;
  text-align: left;
}

.checkbox-form label {
  margin-left: 1em;
}

.checkbox-form .item {
  position: relative;
  padding-left: 31px;
  margin-bottom: 12px;
  cursor: pointer;
  width: 80%;
  height: 100%;
  font-family: Jost, sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0px;
  text-align: left;
}

.checkbox-form .item input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.textCheckbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-form .checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  height: 14px;
  width: 14px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.checkmark {
  position: absolute;
  top: 6px;
  left: 0;
  right: 0;
  height: 14px;
  width: 14px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
}

.checkbox-form .item:hover input ~ .checkmark {
  background-color: #492b7c;
}

.checkbox-form .item input:checked ~ .checkmark {
  background-color: #492b7c;
}

.checkbox-form .checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-form .item input:checked ~ .checkmark:after {
  display: block;
}

.checkbox-form .item .checkmark:after {
  left: 5px;
  top: 2px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
