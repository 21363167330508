<template>
  <div class="radio-toggle-container">
    <div
      @click="toggleSelection"
      :key="selectedID"
      :id="selectedID"
      :class="{
        'radio-toggle-select': true,
        isOn,
        'green-variant': !variant || variant === 'green',
        'yellow-variant': variant === 'yellow',
      }"
    >
      <div :class="{ 'radio-toggle-off': true, selected: !isOn }"></div>
      <div :class="{ 'radio-toggle-on': true, selected: isOn }"></div>
    </div>
    <div
      :class="{
        'radio-toggle-value': true,
        'radio-toggle-value-hidden': !showText,
      }"
    >
      {{ showText ? selectedValue : '.' }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

export default defineComponent({
  name: 'RadioToggle',
  props: {
    onValue: {
      type: String,
      required: true,
    },
    offValue: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: false,
    },
    defaultValue: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    showText: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      validator: (val: string) => ['green', 'yellow'].includes(val),
    },
  },
  emits: ['selected'],
  setup(props, { emit }) {
    const onVal = ref(props.onValue);
    const offVal = ref(props.offValue);
    const selectedValue = ref(props.defaultValue ?? props.offValue);
    const isOn = ref(onVal.value === selectedValue.value);
    const displayText = ref(props.showText);
    const selectedID = ref(props.id || '');

    const toggleSelection = () => {
      if (!props.editable) return;
      selectedValue.value = isOn.value ? offVal.value : onVal.value;
      isOn.value = !isOn.value;
      emit('selected', { value: selectedValue.value, key: selectedID.value });
    };

    return {
      isOn,
      selectedValue,
      toggleSelection,
      displayText,
      selectedID,
    };
  },
});
</script>

<style lang="scss">
@import '../../assets/scss/_colors.scss';

.radio-toggle {
  &-container {
    display: flex;
    width: auto;
  }

  &-value {
    display: flex;
    font-family: Jost, 'sans-serif';
    margin-left: 10px;

    &-hidden {
      visibility: hidden !important;
    }
  }

  &-select {
    display: flex;
    border-radius: 10px;
    width: 40px;
    background-color: $brand-grey-400;
    justify-content: space-between;
    cursor: pointer;
    padding: 2px;

    &.isOn {
      &.green-variant {
        background-color: $status-radio-success;
      }

      &.yellow-variant {
        background-color: $brand-primary-yellow-400;
      }
    }
  }

  &-on {
    clip-path: circle();
    width: 40%;
    background-color: $brand-white;
    justify-self: flex-end;
    visibility: hidden;
    margin: 0;

    &.selected {
      visibility: visible;
    }
  }

  &-off {
    clip-path: circle();
    width: 40%;
    background-color: $brand-white;
    justify-self: flex-start;
    visibility: hidden;

    &.selected {
      visibility: visible;
    }
  }
}
</style>
